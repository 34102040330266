import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import './scss/Account.scss';
import { addAuthentication } from '../../../components/addAuthentication';
import {
  AccountInformation,
  AdminInformation,
  DashboardPageHeading,
} from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

function AccountPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Dashboard - My Account</title>
      </Helmet>
      <DashboardLayout>
        <div className="AccountPage">
          <DashboardAccountPageHeading />
          <div className="AccountPage-container">
            <AccountInformation />
            <AdminInformation />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

const DashboardAccountPageHeading = () => (
  <DashboardPageHeading
    heading={'My Account'}
    links={[{ label: 'Account Information', to: '/dashboard/account' }]}
  />
);

export default addAuthentication(AccountPage);
